import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  TextField,
  InputAdornment,
  Typography,
  CardContent,
  CardHeader,
  CircularProgress,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../../api";

// Define the validation schema with Yup

const CreditCard = () => {
  const location = useLocation();
  const { selectedCards, totalcash, snValue, device_info } = location.state || {
    selectedCards: [],
    totalcash: [],
    snValue: "",
    device_info: [],
  };
  const navigate = useNavigate();
  const [formcreditcard, setFormcreditcard] = useState(null);
  const [dataOrder, setDataOrder] = useState(null);

  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const combinedValues = selectedCards
      .map((item) => String(item.device_number).padStart(2, "0"))
      .join("");

    const orderDescs = `${snValue}-${combinedValues}`;

    const fetchData = async () => {
      setLoading(true);
      try {
        const response1 = await api
          .post(
            `/OPS/ops_order/wallet`,
            {
              orderDesc: orderDescs,
              amount: totalcash,
              "product_id": selectedCards?.[0]?.product_id,
              "device_id": device_info?.data?.[0]?.id
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (ret) => {
            const { data } = ret;
            console.log(data);
            if (data?.data?.orderId) {
              console.log("data");
              const response2 = await api.post(
                `/OPS/ops_order_response/wallet/${data?.data?.orderId}`,
                {}
              );
              const { data: data2 } = response2;
              console.log(data2)
              const response3 = await api.get(
                `/OPS/ops_order/wallet/orderid/${data2?.data?.order?.orderId}`
              );
              const { data: data3 } = response3;
              // console.log(data3)
              // navigate(data3?.webPaymentUrl)
              window.open(data3?.webPaymentUrl, "_self");
              // // const response4 = await api.post(
              // //   `${data3?.data?.link}`,
              // //   {access_token:data3?.data?.token},{headers:{"Content-Type": "application/json"}}
              // // );
              // // const { data: data4 } = response4;
              // // console.log(data4)
              // setFormcreditcard(<><div dangerouslySetInnerHTML={{ __html: data3.data.form }} /></>)
              // // setQrCodeBase64(data3?.qrcode);
              // setDataOrder(data3);
            } else {
              // navigate("/alert");
            }
          })
          .catch(function (error) {
            // navigate("/alert");
          });
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    // เมื่อ component ถูก mount แล้วให้ทำการ submit ฟอร์ม
    if (formcreditcard) {
      const form = document.getElementById('__PostForm');
      if (form) {
        form.submit();
      }
    }
  }, [formcreditcard]);

  // useEffect(() => {
  //   let interval;
  //   if (dataOrder) {
  //     interval = setInterval(async () => {
  //       const response = await getCallback(dataOrder);
  //       if (response?.data?.[0]?.callback_status == 0) {
  //         // handlealertClick(dataOrder);
  //       }
  //     }, 5000);
  //   }
  //   return () => clearInterval(interval); // This is important
  // }, [dataOrder]);

  // const getCallback = async (data) => {
  //   const response = await api.get(
  //     `/OPS/ops_order?orderId=${data?.data?.orderId}`
  //   );
  //   return response.data;
  // };

  return (
    <>
      {loading ? (
        <Box
          style={{ height: "100dvh" }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={80} />
          <Typography position="absolute">loading...</Typography>
        </Box>
      ) : (<Box
        sx={{
          margin: 0,
          textAlign: "center",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          position: "relative", // จัดการตำแหน่ง background ให้อยู่เต็มหน้าจอ
        }}
      >
        {/* กล่องที่มี TextField ของบัตรเครดิต */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: 8,
            padding: 2,
            marginTop: 2,
          }}
        >
          {/* {formcreditcard} */}
          {/* <Card
          sx={{
            width: "366px",
            height: "250px",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            boxShadow: "0px 4px 4px 0px #00000040",
            border: "3px solid  #006EF980",
            borderRadius: "10px",
            position: "relative",
          }}
        >
          <Box sx={{ paddingLeft: 3, paddingRight: 3, paddingTop: 2 }}>
            <Controller
              name="cardNumber"
              control={control}
              render={({ field }) => (
                <InputMask mask="9999 9999 9999 9999" {...field} maskChar="">
                  {(inputProps) => (
                    <TextField
                      {...inputProps}
                      label="หมายเลขบัตร"
                      variant="standard"
                      fullWidth
                      error={!!errors.cardNumber}
                      helperText={
                        errors.cardNumber ? errors.cardNumber.message : ""
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <img
                              src="/logos/visa.png"
                              alt="icon"
                              style={{ width: 25, height: 21 }}
                            />
                            <img
                              src="/logos/mastercard.png"
                              alt="icon"
                              style={{ width: 23, height: 14 }}
                            />
                            <img
                              src="/logos/jcb.png"
                              alt="icon"
                              style={{ width: 21, height: 16 }}
                            />
                            <img
                              src="/logos/unionpay.png"
                              alt="icon"
                              style={{ width: 22, height: 14 }}
                            />
                          </InputAdornment>
                        ),
                        disableUnderline: false,
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: "16px",
                          fontFamily: "Kanit, sans-serif",
                          fontWeight: 400,
                          color: "#696868",
                        },
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          fontSize: "16px",
                          fontFamily: "Kanit, sans-serif",
                          fontWeight: 400,
                          color: "#000000",
                        },
                        "& .MuiInputBase-input": {
                          width: "70%",
                        },
                      }}
                    />
                  )}
                </InputMask>
              )}
            />
          </Box>
          <Box sx={{ paddingLeft: 3, paddingRight: 3, paddingTop: 3 }}>
            <Controller
              name="cardName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ชื่อผู้ถือบัตร"
                  variant="standard"
                  fullWidth
                  error={!!errors.cardName}
                  helperText={errors.cardName ? errors.cardName.message : ""}
                  InputProps={{
                    disableUnderline: false,
                  }}
                  InputLabelProps={{
                    style: {
                      fontSize: "16px",
                      fontFamily: "Kanit, sans-serif",
                      fontWeight: 400,
                      color: "#696868",
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      fontSize: "16px",
                      fontFamily: "Kanit, sans-serif",
                      fontWeight: 400,
                      color: "#000000",
                    },
                  }}
                />
              )}
            />
          </Box>
          <Box
            sx={{
              paddingLeft: 3,
              paddingRight: 3,
              paddingTop: 2,
              display: "flex",
            }}
          >
            <Controller
              name="expiryDate"
              control={control}
              render={({ field }) => (
                <InputMask mask="99/99" {...field} maskChar="">
                  {(inputProps) => (
                    <TextField
                      {...inputProps}
                      label="วันหมดอายุ (MM/YY)"
                      variant="standard"
                      fullWidth
                      error={!!errors.expiryDate}
                      helperText={
                        errors.expiryDate ? errors.expiryDate.message : ""
                      }
                      InputProps={{
                        disableUnderline: false,
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: "16px",
                          fontFamily: "Kanit, sans-serif",
                          fontWeight: 400,
                          color: "#696868",
                        },
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          fontSize: "16px",
                          fontFamily: "Kanit, sans-serif",
                          fontWeight: 400,
                          color: "#000000",
                        },
                      }}
                    />
                  )}
                </InputMask>
              )}
            />

            <Box sx={{ marginLeft: 6 }}>
              <Controller
                name="cvv"
                control={control}
                render={({ field }) => (
                  <InputMask mask="9999" {...field} maskChar="">
                    {(inputProps) => (
                      // <TextField
                      //   {...inputProps}
                      //   label="CVV"
                      //   variant="outlined"
                      //   fullWidth
                      //   type="password"
                      //   error={!!errors.cvv}
                      //   helperText={errors.cvv ? errors.cvv.message : ''}
                      // />
                      <TextField
                        {...inputProps}
                        label="CVV"
                        variant="standard"
                        fullWidth
                        type="password"
                        error={!!errors.cvv}
                        helperText={errors.cvv ? errors.cvv.message : ""}
                        InputProps={{
                          disableUnderline: false,
                        }}
                        InputLabelProps={{
                          style: {
                            fontSize: "16px",
                            fontFamily: "Kanit, sans-serif",
                            fontWeight: 400,
                            color: "#696868",
                          },
                        }}
                        sx={{
                          "& .MuiInputBase-root": {
                            fontSize: "16px",
                            fontFamily: "Kanit, sans-serif",
                            fontWeight: 400,
                            color: "#000000",
                          },
                        }}
                      />
                    )}
                  </InputMask>
                )}
              />
            </Box>
          </Box>
        </Card> */}
          {/* <Box>
          <Button
            variant="contained"
            color="primary"
            sx={{
              padding: "12px 24px",
              background: "#006EF9",
              borderRadius: "32px",
              boxShadow: "0px 3px 4px 0px #00000040",
              width: "366px",
              height: "51px",
              "&:hover": {
                background: "#0099FF",
              },
              fontSize: "22px",
              fontWeight: "bold",
              fontFamily: "Kanit, sans-serif",
              fontWeight: 500,
              color: "#FFFFFF",
            }}
            type="submit"
            onClick={handlealertClick}
          >
            ยืนยัน
          </Button>
        </Box> */}
        </Box>

        <Box
          sx={{
            textAlign: "center",
            backgroundImage: "url('/logos/Wave2.png')",
            backgroundSize: "cover",
            backgroundPosition: "top",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "328px",
            marginTop: "auto",
          }}
        />
      </Box>)}
    </>
  );
};

export default CreditCard;
